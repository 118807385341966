<template>
    <section v-if="getActiveStore.role != 'STAFF'">
      <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">LEAK DETECTION REPORT</div>
                <div class="level-right has-text-centered">
                    <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button>
                    <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button> -->
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-4">
                <b-field>
                    <date-range-selector
                        :dateRange="salesDateRange"
                        @set-date-range="setDateRange"
                        >
                    </date-range-selector>                    
                </b-field>
            </div>
        </div>

        <div class="panel-block">
            <b-tabs class="control" v-model="activeTab" size="is-medium" position="is-left" type="is-boxed">
                <b-tab-item label="Tank Release Detection" type="is-boxed">
                    <b-table class="control" 
                        v-if="sortedTankRecords.length > 0"
                        :data='sortedTankRecords'
                        :paginated="isPaginated"
                        :per-page="perPage"
                        paginationPosition="both"
                        sortIcon="arrow-up"
                        sortIconSize="is-small"
                        default-sort=enteredDate
                        defaultSortDirection='asc'
                        >

                    <template>
                        <b-table-column field="date" label="Date" sortable sorticon  v-slot="props">
                            {{ props.row.date | formatDate }}
                        </b-table-column>
                        <b-table-column field="name" label="Tank#" sortable sorticon  v-slot="props">
                            {{ props.row.number  }}
                        </b-table-column>
                        <b-table-column field="name" label="Tank" sortable sorticon  v-slot="props">
                            {{ props.row.name  }}
                        </b-table-column>
                        <b-table-column field="product" label="Product" sortable sorticon  v-slot="props">
                            {{ props.row.product  }}
                        </b-table-column>

                        <b-table-column field="type" label="Test Type" sortable sorticon  v-slot="props">
                            {{ props.row.type  }}
                        </b-table-column>

                        <b-table-column field="result" label="Result" sortable sorticon  v-slot="props">
                            {{ props.row.result  }}
                        </b-table-column>
                    </template>
                    
                    <template slot="empty">
                        <data-loading 
                            :isLoading="isLoading" 
                            :isFullPage="isFullPage"> 
                        </data-loading>
                    </template>

                    </b-table>
                    <div class="title has-text-danger is-5" v-else>** Tank Release Status Information Not Available **</div>
                </b-tab-item>
                <b-tab-item label="Line Release Detection">
                    <b-table class="control"
                        v-if="sortedTankRecords.length > 0" 
                        :data='sortedTankRecords'
                        :paginated="isPaginated"
                        :per-page="perPage"
                        paginationPosition="both"
                        sortIcon="arrow-up"
                        sortIconSize="is-small"
                        default-sort=enteredDate
                        defaultSortDirection='asc'
                        >

                    <template>
                        <b-table-column field="date" label="Date" sortable sorticon  v-slot="props">
                            {{ props.row.date | formatDate }}
                        </b-table-column>
                        <b-table-column field="name" label="Tank#" sortable sorticon  v-slot="props">
                            {{ props.row.number  }}
                        </b-table-column>
                        <b-table-column field="name" label="Tank" sortable sorticon  v-slot="props">
                            {{ props.row.name  }}
                        </b-table-column>
                        <b-table-column field="product" label="Product" sortable sorticon  v-slot="props">
                            {{ props.row.product  }}
                        </b-table-column>

                        <b-table-column field="type" label="Test Type" sortable sorticon  v-slot="props">
                            {{ props.row.type  }}
                        </b-table-column>

                        <b-table-column field="result" label="Result" sortable sorticon  v-slot="props">
                            {{ props.row.result  }}
                        </b-table-column>
                    </template>
                    
                    <template slot="empty">
                        <data-loading 
                            :isLoading="isLoading" 
                            :isFullPage="isFullPage"> 
                        </data-loading>
                    </template>

                    </b-table>
                    <div class="title has-text-danger is-5" v-else>** Line Release Status Information Not Available **</div>
                </b-tab-item>
                <b-tab-item label="Sensor Status">
                    <b-table class="control" 
                        v-if="sortedSensorRecords.length > 0"
                        :data='sortedSensorRecords'
                        :paginated="isPaginated"
                        :per-page="perPage"
                        paginationPosition="both"
                        sortIcon="arrow-up"
                        sortIconSize="is-small"
                        default-sort=enteredDate
                        defaultSortDirection='asc'
                        >

                    <template>
                        <b-table-column field="date" label="Date" sortable sorticon  v-slot="props">
                            {{ props.row.date | formatDate }}
                        </b-table-column>
                        <!-- <b-table-column field="name" label="Tank" sortable sorticon  v-slot="props">
                            {{ props.row.sensorId  }}
                        </b-table-column> -->
                        <b-table-column field="sensorNum" label="Sensor#" sortable sorticon  v-slot="props">
                            {{ props.row.sensorNum  }}
                        </b-table-column>
                        <b-table-column field="location" label="Location" sortable sorticon  v-slot="props">
                            {{ props.row.location  }}
                        </b-table-column>

                        <!-- <b-table-column field="type" label="Type" sortable sorticon  v-slot="props">
                            {{ props.row.type  }}
                        </b-table-column> -->

                        <b-table-column field="type" label="Sensor Type" sortable sorticon  v-slot="props">
                            {{ props.row.sensorType  }}
                        </b-table-column>

                        <b-table-column field="type" label="Category" sortable sorticon  v-slot="props">
                            {{ props.row.category  }}
                        </b-table-column>

                        <b-table-column field="result" label="Result" sortable sorticon  v-slot="props">
                            {{ props.row.result.replace('Sensor ','')  }}
                        </b-table-column>
                    </template>
                    
                    <template slot="empty">
                        <data-loading 
                            :isLoading="isLoading" 
                            :isFullPage="isFullPage"> 
                        </data-loading>
                    </template>

                    </b-table>
                    <div class="title has-text-danger is-5" v-else>** Sensor Status Information Not Available **</div>
                </b-tab-item>
            </b-tabs>

        </div>

      </div>
      <leak-detection-pdf
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :dateRange="salesDateRange"
            :sortedTankRecords="sortedTankRecords"
            :sortedSensorRecords="sortedSensorRecords"
            @close-pdf-dialog="togglePDFDialog"
        ></leak-detection-pdf>
    </section>
</template>

<script>

import UserStore from '../../../store/UserStore'
import axios from 'axios'
import DateRangeSelector from '../../../components/app/DateRangeSelector.vue'
import DataLoading from '../../../components/app/DataLoading'
import LeakDetectionPdf from './LeakDetectionPDF.vue'

export default {

    components: {
        DataLoading,
        DateRangeSelector,
        LeakDetectionPdf
    },

    metaInfo: {
        title: 'Leak Detection Report'
    },

    data() {
        return {
            user: {},
            sortedTankRecords: [],
            sortedSensorRecords: [],
            tankRecords: [],
            sensorRecords: [],
            isPaginated: true,
            perPage: 10,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            isShowPDFDialog: false,
            activeTab: 0,
            salesDateRange: [new Date(), new Date()],
        }
    },

    methods: {

        async fetchTankRecords() {

            if (this.user.activeStore) {

                this.sortedTankRecords = []
                this.tankRecords = []
                var startDate = this.salesDateRange[0].toISOString().split('T')[0]
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        "/tanks/leak/result?"

                url = url + "fromDate="+startDate+"&toDate="+endDate
                                
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.tankRecords = response.data.data
                        this.groupByTankAndDate()
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

        },

        async fetchSensorRecords() {

            if (this.user.activeStore) {

                this.sortedSensorRecords = []
                this.sensorRecords = []
                var startDate = this.salesDateRange[0].toISOString().split('T')[0]
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        "/sensors/status?"

                url = url + "fromDate="+startDate+"&toDate="+endDate
                                
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.sensorRecords = response.data.data
                        this.groupBySensorAndDate()
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

        },        

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },

        groupByTankAndDate() {
            var tempArray = []
            var curItem = {}
            this.tankRecords.forEach(item => {
                item.id = item.tank.id
                item.sortId=item.date + String(item.tank.id).padStart(4, '0')
                item.name = item.tank.name
                item.product = item.tank.product
                item.minimumVolume = item.tank.minimumVolume
                item.number = item.tank.number
            })
            // this.sortedTankRecords = this.sortArrayByProp(this.tankRecords, "date", "asc")
            // tempArray = this.sortArrayByProp(this.sortedTankRecords, "id", "asc")

            tempArray = this.sortArrayByProp(this.tankRecords, "sortId", "asc")

            this.sortedTankRecords = []
            tempArray.forEach(item => {
                if (curItem.id !== item.id || curItem.date !== item.date || curItem.result !== item.result) {
                    this.sortedTankRecords.push(item)
                    curItem = item
                }
            })

            if (this.sortedTankRecords.length === 0) {
                this.activeTab = 2
            } else {
                this.activeTab = 0
            }

        },

        groupBySensorAndDate() {

            var tempArray = []
            var curItem = {}
            this.sensorRecords.forEach(item => {
                item.sortId=item.date + String(item.sensor.id).padStart(4, '0')
                item.sensorId = item.sensor.id
                item.sensorNum = item.sensor.sensor
                item.location = item.sensor.location
                item.sensorType = item.sensor.type
                item.category = item.sensor.category
            })
            // this.sortedSensorRecords = this.sortArrayByProp(this.sensorRecords, "date", "asc")
            
            // tempArray = this.sortArrayByProp(this.sortedSensorRecords, "sensorId", "asc")

            tempArray = this.sortArrayByProp(this.sensorRecords, "sortId", "asc")

            this.sortedSensorRecords = []
            tempArray.forEach(item => {
                if (curItem.date !== item.date || curItem.sensorId !== item.sensorId) {
                    this.sortedSensorRecords.push(item)
                    curItem = item
                }
            })

        },
        
    },

    computed: {
        getActiveStore() {
            return UserStore.getters.getActiveStore
        },
    },

    watch: {
        salesDateRange: function() {
            if (this.getActiveStore.role != 'STAFF') {
                this.fetchTankRecords()
                    .then(() => {
                    this.groupByTankAndDate()
                })
                
                this.fetchSensorRecords()
                    .then(() => {
                        this.groupBySensorAndDate()
                    })
            } else {
                this.isLoading = false
            }
        },


    },

    mounted() {
        this.user = this.getUser()
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]
        this.fetchTankRecords()
            .then(() => {
                this.groupByTankAndDate()
            }) 

        this.fetchSensorRecords()
            .then(() => {
                this.groupBySensorAndDate()
            }) 
    },
}
</script>